import { LISTING_ATTRIBUTE_NAME, LISTING_ATTRIBUTE_VALUE } from '@/constants/listingAttributes'
import { type Listing } from '@/types/search'

/**
 * Checks if listing is a dealer listing based on its attributes
 */
export const isDealerListing = (attributes?: Listing['attributes']): boolean => {
  const filteredAttributes = (attributes?.all ?? []).filter((attr) => !!attr)

  return (
    filteredAttributes.find(
      (attribute) => attribute && attribute.canonicalName === LISTING_ATTRIBUTE_NAME.FOR_SALE_BY
    )?.canonicalValues?.[0] === LISTING_ATTRIBUTE_VALUE.DEALER
  )
}
