import { BorderRadiusKeys, ThemeProps } from '@kijiji/theme'
import styled, { DefaultTheme } from 'styled-components'

import { styleFromTypography } from '@/ui/helpers/styleFromTypography'

import { BadgeSize, BadgeVariant } from './Badge'

const getPaddingBySize = (size: BadgeSize, theme: DefaultTheme) => {
  switch (size) {
    case BadgeSize.SMALL:
      return `0.3rem ${theme.spacing.mini}`
    case BadgeSize.MEDIUM:
      return '0.1rem 0.3rem'
    case BadgeSize.LARGE:
      return '0.3rem 0.8rem'
    case BadgeSize.XLARGE:
      return '0.4rem 0.8rem'
    default:
      return '0.1rem 0.3rem'
  }
}

const getBorderByVariant = (variant: BadgeVariant, theme: DefaultTheme) => {
  switch (variant) {
    case BadgeVariant.LIGHT1:
      return `1px solid ${theme.colors.grey.light1};`
    case BadgeVariant.LIGHT3:
      return `1px solid ${theme.colors.grey.light3}`
    default:
      return `1px solid ${theme.colors.grey.light1};`
  }
}

const getTypographyBySize = (size: BadgeSize, theme: DefaultTheme) => {
  switch (size) {
    case BadgeSize.SMALL:
      return styleFromTypography(theme.typography.body.xxSmall)
    case BadgeSize.MEDIUM:
      return styleFromTypography(theme.typography.body.xSmall)
    case BadgeSize.LARGE:
      return styleFromTypography(theme.typography.body.xSmall)
    case BadgeSize.XLARGE:
      return styleFromTypography(theme.typography.body.small)
    default:
      return styleFromTypography(theme.typography.body.xSmall)
  }
}

type BadgeElementProps = {
  size: BadgeSize
  variant: BadgeVariant
  borderRadius: BorderRadiusKeys
  color?: string
  backgroundColor?: string
  weight: keyof ThemeProps['fontWeight']
  gap?: string
}

export const BadgeElement = styled.span<BadgeElementProps>(
  ({
    theme,
    size,
    variant,
    borderRadius,
    color,
    backgroundColor,
    weight,
    gap,
  }) => `
  font-weight: ${theme.fontWeight[weight]};
  color: ${color || theme.colors.grey.light1};
  border-radius: ${theme.borderRadius[borderRadius]};
  background-color: ${backgroundColor || theme.colors.white};
  padding: ${getPaddingBySize(size, theme)};
  border: ${getBorderByVariant(variant, theme)};
  ${getTypographyBySize(size, theme)};
  display: flex;
  align-items: center; 
  gap: ${gap || theme.spacing.mini};
  height: fit-content;
  width: fit-content;
  white-space: nowrap;
`
)
